import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import Theme from "components/template/Theme";
import Layout from "components/layout";
import history from "./history";
import mockServer from "./mock";
import appConfig from "configs/app.config";
import "./locales";
import { ScrollProvider } from "views/bots/newBot/scroll-context";

const environment = process.env.NODE_ENV;
if (appConfig.enableMock) {
  mockServer({ environment });
}
function App() {
  useEffect(() => {
    const preventZoom = (event) => {
      if (event.ctrlKey || event.metaKey) {
        //Control or Command key is pressed"
        event.preventDefault();
      }
    };
    const handleDragOver = (e) => {
      // In Firefox, the onDrag event does not provide clientX and clientY coordinates.
      // As a workaround, we capture these coordinates from the onDragOver event
      // and forward them to the dragged element.
      e.preventDefault();
      e.stopPropagation();
      e.dataTransfer.dropEffect = "move";
      // Retrieve the dragged element's ID
      const draggedElementId = e.dataTransfer.getData("text/plain");
      if (!!draggedElementId) {
        const draggedElement = document.getElementById(draggedElementId);
        if (draggedElement) {
          // Create and dispatch a custom drag event
          const mouseEvent = new MouseEvent("drag", {
            bubbles: true,
            cancelable: true,
            clientX: e.clientX,
            clientY: e.clientY,
          });
          draggedElement.dispatchEvent(mouseEvent);
        }
      }
    };

    window.addEventListener("wheel", preventZoom, { passive: false });

    // Attach the dragover event listener to the window
    window.addEventListener("dragover", handleDragOver);

    return () => {
      window.removeEventListener("wheel", preventZoom);
      window.removeEventListener("dragover", handleDragOver);
    };
  }, []);

  return (
    <Provider store={store}>
      <ScrollProvider>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter history={history}>
            <Theme>
              <Layout />
            </Theme>
          </BrowserRouter>
        </PersistGate>
      </ScrollProvider>
    </Provider>
  );
}

export default App;
